<template>
  <div class="user-account-section">
    <Breadcrumbs />
    <b-container class="account-page">
      <div class="account-page-title">
        {{ $t("my_account") }}
      </div>
      <b-tabs
        pills
        card
        vertical
        v-model="tabIndex"
        :nav-wrapper-class="{
          'cst-tab': true,
          sidebarhide: !isTabShow && isMobileScreen,
        }"
      >
        <div>
          <b-tab @click="hideTabs" :title="accountTabs[0]"
            ><AccountMyAccount @showTabs="showTabs" :isTabShow="isTabShow" />
          </b-tab>
        </div>
        <div>
          <b-tab
            @click="
              hideTabs();
              showOrderList = true;
            "
            :title="accountTabs[1]"
          >
            <b-card-text>
              <AccountOrders
                @showTabs="showTabs"
                @chageView="(value) => (showOrderList = value)"
                :isTabShow="isTabShow"
                :showOrders="showOrderList"
              />
            </b-card-text>
          </b-tab>
        </div>
        <b-tab @click="hideTabs" :title="accountTabs[2]">
          <b-card-text>
            <AccountWishlist
              @showTabs="showTabs"
              :isTabShow="isTabShow"
            /> </b-card-text
        ></b-tab>
        <b-tab @click="hideTabs" :title="accountTabs[3]">
          <AccountAddressBook @showTabs="showTabs" :isTabShow="isTabShow" />
        </b-tab>
        <b-tab @click="hideTabs" :title="accountTabs[4]">
          <AccountInformation @showTabs="showTabs" :isTabShow="isTabShow" />
        </b-tab>
        <b-tab @click="hideTabs" :title="accountTabs[5]">
          <NewsletterSubscription @showTabs="showTabs" :isTabShow="isTabShow" />
        </b-tab>
        <b-tab @click="goLogout" :title="accountTabs[6]"> </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>

<script>
import Breadcrumbs from "@/base/core/components/breadcrumb/Breadcrumbs.vue";
import AccountInformation from "@/base/core/components/account/AccountInformation.vue";
import AccountMyAccount from "@/esf_antarctica_feestenkleding/core/components/account/AccountMyAccount.vue";
import AccountAddressBook from "@/base/core/components/account/AccountAddressBook.vue";
import NewsletterSubscription from "@/base/core/components/account/NewsletterSubscription.vue";
import AccountWishlist from "@/base/core/components/account/AccountWishlist.vue";
import AccountOrders from "@/base/core/components/account/AccountOrders.vue";
import { isMobile } from "mobile-device-detect";
import config from "@config";

export default {
  name: "Account",
  components: {
    Breadcrumbs,
    AccountInformation,
    AccountAddressBook,
    AccountMyAccount,
    NewsletterSubscription,
    AccountWishlist,
    AccountOrders,
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
        this.setBreadcrumb(val);
      },
    },

    isMobileScreen() {
      return isMobile;
    },
  },

  mounted() {
    const { tabIndex } = this.$route.params;

    if (tabIndex) {
      this.tabIndex = tabIndex;
    } else {
      this.tabIndex = 0;
    }
  },
  data() {
    return {
      isTabShow: true,
      statusAddForm: true,
      showOrderList: true,
      thanksActive: false,
      accountTabs: {
        0: "Dashboard",
        1: this.$t("my_orders"),
        2: this.$t("my_wishlist"),
        3: this.$t("address_book"),
        4: this.$t("account_information"),
        5: this.$t("newsletters"),
        6: this.$t("logout"),
      },
      form: {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        input7: "",
        input8: "",
        input9: "",
        checkbox2: false,
      },
    };
  },
  methods: {
    hideTabs() {
      this.isTabShow = false;
    },
    showTabs() {
      this.isTabShow = true;
    },
    setBreadcrumb(tabIndex = 0) {
      const bcrumb = {
        current: this.accountTabs[tabIndex],
        routes: [{ name: this.$t("my_account"), router_link: "" }],
      };
      this.$store.commit("breadcrumbs/set", bcrumb);
    },
    goLogout() {
      this.visible = false;
      this.tabIndex = 0;
      this.$store.dispatch("user/logout");
      this.$router.push("/");
    },
    formSubmit() {
      this.thanksActive = true;
    },
    formSubmitValidate() {},
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "title",
          content: this.$t("my_account"),
        },
        {
          name: "keywords",
          content: this.$t("my_account"),
        },
        {
          name: "description",
          content: this.$t("my_account"),
        },
      ],
      title: this.$t("my_account"),
      link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
    };
  },
};
</script>

<style lang="scss">
</style>
