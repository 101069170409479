<template>
  <div
    class="my-account-page"
    :class="{ 'hide-tab-content': isTabShow && isMobileScreen }"
  >
    <div class="address-block">
      <h2>Dashboard</h2>
      <div class="back-link" v-if="isMobileScreen" @click="$emit('showTabs')">
        <b-icon icon="chevron-left"></b-icon> <span>{{ $t("go_back") }}</span>
      </div>
      <b-row>
        <b-col sm="12">
          <div class="contact-wrap">
            <h3 class="semiBold-18">{{ $t("contact_information") }}</h3>
            <div class="name-wrap">
              <div class="name">
                <span>{{ user.firstname }} {{ user.lastname }}</span>
                <span>{{ user.email }}</span>
              </div>
              <div class="name-action">
                <b-link class="c-btn c-btn-primary" @click="goToEditAccount">{{
                  $t("edit")
                }}</b-link
                >
              </div>
            </div>
            <div class="address-wrap">
              <div class="address">
                <h3 class="semiBold-18">
                  {{ $t("address_book") }} &nbsp;&nbsp;&nbsp;<b-link
                    class="link"
                  ></b-link>
                </h3>
              </div>
              <div class="address-action">
                <div class="address-box">
                  <h3 class="semiBold-18">
                    {{ $t("default_billing_address") }}
                  </h3>
                  <div v-if="defaultBilling == null">
                    {{ $t("no_default_billing_address") }}
                  </div>
                  <address v-if="defaultBilling != null">
                    <span
                      >{{ defaultBilling.firstname }}
                      {{ defaultBilling.lastname }}</span
                    >
                    <span v-if="defaultBilling.company != null">{{
                      defaultBilling.company
                    }}</span>
                    <span>{{ defaultBilling.street[0] }}</span>
                    <span
                      >{{ defaultBilling.city }},
                      {{ defaultBilling.postcode }}</span
                    >
                    <span>{{ countryName(defaultBilling.country_code) }}</span>
                    <span
                      >T:<a :href="`tel:` + defaultBilling.telephone">
                        {{ defaultBilling.telephone }}</a
                      ></span
                    >
                    <span v-if="defaultBilling.vat_id != null"
                      >VAT:{{ defaultBilling.vat_id }}</span
                    >
                  </address>
                  <b-link
                    class="c-btn c-btn-primary"
                    @click="goToEditAddress(defaultBilling.id)"
                    >{{ $t("edit_address") }}</b-link
                  >
                </div>
                <div class="address-box">
                  <h3 class="semiBold-18">
                    {{ $t("default_shipping_address") }}
                  </h3>
                  <div v-if="defaultShipping == null">
                    {{ $t("no_default_shipping_address") }}
                  </div>
                  <address v-if="defaultShipping != null">
                    <span
                      >{{ defaultShipping.firstname }}
                      {{ defaultShipping.lastname }}</span
                    >
                    <span v-if="defaultShipping.company != null">{{
                      defaultShipping.company
                    }}</span>
                    <span>{{ defaultShipping.street[0] }}</span>
                    <span
                      >{{ defaultShipping.city }},
                      {{ defaultShipping.postcode }}</span
                    >
                    <span>{{ countryName(defaultShipping.country_code) }}</span>
                    <span
                      >T:<a :href="`tel:` + defaultShipping.telephone">
                        {{ defaultShipping.telephone }}</a
                      ></span
                    >
                    <span v-if="defaultShipping.vat_id != null"
                      >VAT:{{ defaultShipping.vat_id }}</span
                    >
                  </address>
                  <b-link
                    class="c-btn c-btn-primary"
                    @click="goToEditAddress(defaultShipping.id)"
                    >{{ $t("edit_address") }}</b-link
                  >
                </div>
              </div>
            </div>
            <div class="manage-address">
              <b-link class="c-btn c-btn-primary" @click="goToManageAddress">{{
                $t("manage_addresses")
              }}</b-link>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";

export default {
  name: "AccountMyAccount",
  components: {},

  props: {
    isTabShow: {
      type: Boolean,
    },
  },

  data() {
    return {};
  },
  computed: {
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    defaultBilling() {
      return this.$store.getters["user/getDefaultBillingAddress"];
    },
    defaultShipping() {
      return this.$store.getters["user/getDefaultShippingAddress"];
    },
    isMobileScreen() {
      return isMobile;
    },
  },
  methods: {
    goToEditAccount() {
      this.tabIndex = 4;
    },
    goToEditPassword() {
      this.tabIndex = 6;
    },
    goToEditAddress(id) {
      this.tabIndex = 3;
      this.$store.commit("user/setEditAddressId", id);
    },
    goToManageAddress() {
      this.tabIndex = 3;
    },
    goToEditSubscription() {
      this.tabIndex = 6;
    },
    countryName(countrycode) {
      if (countrycode == "NL") {
        return "Netherlands";
      } else countrycode == "BE";
      return "Belgium";
    },
  },
};
</script>

<style lang="scss" scoped>
</style>